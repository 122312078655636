<template>
  <div class="property-description-wrapper">
    <div class="property-description-wrapper__description">
      <div class="description-heading">Описание на имота:</div>
      <div class="divider"></div>
      {{ description }}
    </div>
    <div class="property-description-wrapper__broker">
      <agency-contact-card
        :agencyName="agencyName"
        :companyEmail="companyEmail"
        :companyPhone="companyPhone"
      ></agency-contact-card>

      <agent-contact-card :brokers="brokers"></agent-contact-card>
    </div>
  </div>
</template>

<script>
import AgentContactCard from "../agents-cards/AgentContactCard.vue";
import AgencyContactCard from "../agencies-cards/AgencyContactCard.vue";
export default {
  components: {
    AgentContactCard,
    AgencyContactCard,
  },
  props: [
    "description",
    "agencyName",
    "companyEmail",
    "companyPhone",
    "brokers",
  ],
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";
</style>
