<template>
  <h2>Препоръчани имоти</h2>
  <section class="recommended-properties-section">
    <div v-for="property in recommendedProperties" :key="property.id">
      <quick-revue-property-card
        :id="property.id"
        :pictures="property.topImageURL" 
        :property="property.regionName" 
        :address="property.subRegionName" 
        :offerType="property.offerTypeName" 
        :propertyType="property.estateTypeName" 
        :priceInEur="property.price" 
        :onFloor="property.floor" 
        :areaSize="property.surfaceAll" 
      ></quick-revue-property-card>
    </div>
  </section>
</template>

<script>
import { usePropertiesStore } from '../stores/PropertiesStore';
import QuickRevuePropertyCard from "./property-cards/QuickRevuePropertyCard.vue";


export default {
  components: {
    QuickRevuePropertyCard,
  },

  computed: {
    propertiesData() {
      const propertiesStore = usePropertiesStore();
      return propertiesStore.propertiesData;
    },
    recommendedProperties() {
      const properties = [...this.propertiesData];
      const randomIndexes = new Set();

      while (randomIndexes.size < 8 && properties.length > 0) {
        const randomIndex = Math.floor(Math.random() * properties.length);
        randomIndexes.add(randomIndex);
      }

      // Convert the Set back to an array
      const uniqueRandomIndexes = [...randomIndexes];

      return uniqueRandomIndexes.map(index => properties[index]);
    },
  },

  mounted() {
    const propertiesStore = usePropertiesStore();
    propertiesStore.fetchHomePage();
  },
};
</script>

<style lang="scss" scoped>
.recommended-properties-section {
  width: 90%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

h2 {
  margin: 30px;
  color: transparent;
  font-size: 26px;
  font-weight: 500;
  background-image: var(--theme-gradient6);
  background-clip: border-box;
  -webkit-background-clip: text;
}
</style>
