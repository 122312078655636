<template>
  <!-- This component is currently NOT in use -->
  <!-- <div class="homepage-wrapper">
      <h1 class="homepage-heading">вашият нов имот ви очаква тук</h1>
        <HomepageSearch/>
  </div> -->
  <div style='display:flex; flex-direction: column; justify-content: center; align-items: center;'>
    <base-loading v-if="isLoading"></base-loading>
    <recommended-properties></recommended-properties>
  </div>
</template>

<script>
import { usePropertiesStore } from "../stores/PropertiesStore";
import RecommendedProperties from "./RecommendedProperties.vue";
export default {
  components: {
    RecommendedProperties,
  },

  computed: {
    isLoading() {
      const propertiesStore = usePropertiesStore();
      return propertiesStore.isLoading;
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/main.scss";
</style>
