<template>
<div>
    <h1>Имоти за продажба</h1>
    <div>
        <sales-listing></sales-listing>
    </div>
</div>
</template>

<script>
import SalesListing from '../layouts/SalesListing.vue'

export default {
    components: {
        SalesListing,
        
    }
}
</script>