<template>
  <footer>
    <div class="footer-wrapper">
      <div class="footer-wrapper__top-border"></div>
      <div class="footer-wrapper__content-wrapper">
        <div>
          <p>Imoti24.net 2023</p>
        </div>
        <div>
          <the-logo></the-logo>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";
</style>
