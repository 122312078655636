<template>
<div>
    <h1>Имоти под наем</h1>
    <div>
        <rent-listing></rent-listing>
    </div>
</div>
</template>

<script>
import RentListing from '../layouts/RentListing.vue' 
export default {
    components: {
        RentListing,
    }
}
</script>